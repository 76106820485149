import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfStore from "@/vue/domain/store/df-store";
import DfBrand from "@/vue/domain/brand/df-brand";
import { CONTAINER_TYPES } from "@/vue/infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import Swal from "sweetalert2";
import DfNewsletterUnsubscribeStatus from "@/vue/domain/newsletter/df-newsletter-unsubscribe-status";
import DfNewsletterSubscribeStatus from "@/vue/domain/newsletter/df-newsletter-subscribe-status";

const DfHeaderMobileComponent = () => Utils.externalComponent2("df-header-mobile");

@Component({
  components: {
    DfHeaderMobile: DfHeaderMobileComponent,
    DfFooter: () => Utils.externalComponent2("df-footer"),
    DfSectionLinks: () => Utils.externalComponent2("df-section-links"),
  },
})
export default class DfNewsletterPageComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  private email: string = null;
  private emailError = false;
  private privacy = false;
  private privacyError = false;
  private name: string = null;
  private nameError = false;
  private lastname: string = null;
  private lastnameError = false;
  private unsubscribeSuccessful = false;
  private subscribeSuccessful = false;
  private ipAdress: string = null;

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get pagePreview(): string {
    return `${Utils.getPublicPath()}/assets/copertina-newsletter.png`;
  }

  get sendyPrivacyText(): string {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_SENDY_PRIVACY_TEXT, `TEXT`);
  }

  get sendyListId(): string {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_SENDY_LIST_ID, `STRING`);
  }

  get sendyStoreField(): string {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_SENDY_STORE_FIELD, `STRING`);
  }

  get sendyLastnameField(): string {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_SENDY_LASTNAME_FIELD, `STRING`);
  }

  get newsletterTitle(): string {
    const propertyTile: string = Utils.getPropertyValue(this.brand, Utils.PROPERTY_SENDY_TITLE, `STRING`);
    return propertyTile ? propertyTile : this.$t("df-newsletter-page.title").toString();
  }

  get newsletterText(): string {
    const propertyText: string = Utils.getPropertyValue(this.brand, Utils.PROPERTY_SENDY_TEXT, `TEXT`);
    return propertyText ? propertyText : null;
  }

  get showBreadcrumb(): boolean {
    return Utils.showBreadcrumb();
  }

  beforeMount() {
     this.getIp();
  }

  @Watch("sendyListId", { immediate: true })
  private sendyListIdChanged() {
    if (!this.sendyListId) {
      this.$router.push({ name: "home-store", params: { storeAlias: this.currentStore.alias } });
    }
  }

  get enablePrivateArea(): boolean {
    return Utils.enablePrivateArea();
  }

  private goToStorePage(store: DfStore) {
    this.$router.push({ name: "store", params: { storeAlias: store.alias } }).catch((err) => err);
  }

  private openMap() {
    window.open(`${Utils.GOOGLE_MAP_URL}${Utils.storeGoogleMapsCoordinates(this.currentStore)}`, "_blank");
  }

  private subscribeNewsletter() {
    this.emailError = !this.email;
    this.privacyError = !this.privacy && !!this.sendyPrivacyText;
    this.nameError = !this.name;
    this.lastnameError = !this.lastname && !!this.sendyLastnameField;

    if (!this.emailError && !this.privacyError && !this.nameError && !this.lastnameError) {
      const params: any = { action: "SUBSCRIBE", listId: this.sendyListId || undefined, email: this.email || undefined, name: this.name || undefined, ipadress: this.ipAdress};
      if (this.sendyStoreField || this.sendyLastnameField) {
        params["customParameters"] = {};
        if (this.sendyStoreField) {
          params["customParameters"][this.sendyStoreField] = this.currentStore.code;
        }
        if (this.sendyLastnameField) {
          params["customParameters"][this.sendyLastnameField] = this.lastname;
        }
      }
      this.service
        .subscribeNewsletter(params)
        .then((status: DfNewsletterSubscribeStatus) => {
          if (status.enumKey === DfNewsletterSubscribeStatus.TRUE.enumKey) {
            this.subscribeSuccessful = true;
            Utils.setAnaliticsTraker({
              event: "newsletter",
              origin: "smt-digitalflyer-ui",
              email: this.email,
            });
          } else {
            Swal.fire({
              title: this.$t("df-newsletter-page.subscribe.error-title").toString(),
              text: this.$t(status.label).toString(),
              icon: "error",
              buttonsStyling: false,
            });
          }
        })
        .catch((error: Error) => {
          Utils.defaultApiErrorHandler(error, this);
        });
    }
  }

  private unsubscribeNewsletter() {
    this.emailError = !this.email;
    this.privacyError = !this.privacy && !!this.sendyPrivacyText;

    if (!this.emailError && !this.privacyError) {
      const params: any = { action: "UNSUBSCRIBE", listId: this.sendyListId, email: this.email, name: this.name };
      this.service
        .unsubscribeNewsletter(params)
        .then((status: DfNewsletterUnsubscribeStatus) => {
          if (status.enumKey === DfNewsletterUnsubscribeStatus.TRUE.enumKey) {
            this.unsubscribeSuccessful = true;
          } else {
            Swal.fire({
              title: this.$t("df-newsletter-page.unsubscribe.error-title").toString(),
              text: this.$t(status.label).toString(),
              icon: "error",
              buttonsStyling: false,
            });
          }
        })
        .catch((error: Error) => {
          Utils.defaultApiErrorHandler(error, this);
        });
    }
  }

  private showNewsletterPrivacy() {
    Swal.fire({
      title: this.$t("df-newsletter-page.swal.privacy.title").toString(),
      html: this.sendyPrivacyText,
      icon: "info",
      customClass: "privacy-modal",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showCancelButton: true,
      confirmButtonText: this.$t("df-newsletter-page.swal.privacy.confirm").toString(),
      cancelButtonText: this.$t("df-newsletter-page.swal.privacy.cancel").toString(),
    }).then((result) => {
      this.privacy = result.value;
    });
  }

  private goBack() {
    Utils.goBack(this);
  }

  private getIp() {
    this.service
      .getIp()
      .then((data: any) => {
        this.ipAdress = data.ip;
      })
      .catch(() => {
        this.ipAdress = null;
      });
  }
}
